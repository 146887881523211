import Vue from 'vue'
import Renderer, { material, materials, injectRequest } from '@yeepay/lowcode-renderer'
import Antd, { AntdMaterials } from '@yeepay/antd-materials'
import http from './utils/request'
import App from './App.vue'
import router from './router'
import store from './store'
import 'ant-design-vue/dist/antd.css'
import '@yeepay/lowcode-renderer/dist/styles/index.less'
import '@yeepay/antd-materials/dist/styles/index.less'
import './assets/base.css'
import LowCodeRender from './components/LowCodeRender.vue'

Vue.config.productionTip = false
Vue.component('LowCodeRender', LowCodeRender)
material.install(AntdMaterials) // antd 物料集
Vue.use(Antd, { materials }) // antd 物料集vueuse
Vue.use(Renderer) // 渲染器 vueuse
// @ts-ignore
injectRequest(http)
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')

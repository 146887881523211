import axios from 'axios'
import { message } from 'ant-design-vue'
// import { getToken } from '@yeepay/client-utils'

const http = axios.create({
  baseURL: '/',
  timeout: 60000, // 超时时间
  headers: {
    'Content-Type': 'application/json',
  },
})
http.interceptors.response.use((res) => {
  if (res.data.code !== 'EC000000') {
    message.error(res.data.message)
    return Promise.reject(new Error(res.data.message))
  }
  return Promise.resolve(res)
}, (error) => error)
export default http

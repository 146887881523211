



























// import { computed } from "@vue/composition-api";
import { Component, Vue, Prop } from "vue-property-decorator"

@Component
export default class Contain extends Vue {
  @Prop({ type: Object, default: () => ({}) }) schema!: { components: string };

  get isPC() {
    // console.log(window.screen.width)
    // return !/Mobi|Android|iPhone/i.test(navigator.userAgent)
    return !/phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone/i.test(navigator.userAgent)
  }
}
